import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import cn from 'classnames';
import Spacing from '../../reused-libraries/Spacing'
import { GatsbyImage } from 'gatsby-plugin-image'
import { CallToAction } from './CallToAction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Card from '@material-ui/core/Card'
import RouterButton from '../shared/RouterButton'
import CardActionArea from '@material-ui/core/CardActionArea'
import RouterLink from '../shared/RouterLink'
import CardContent from '@material-ui/core/CardContent'

export function ArticlePage({children}) {
	return (
		<div className="mb-5">
			<article>
				{children}
			</article>
		</div>
	);
}
ArticlePage.propTypes = {
	children: PropTypes.node.isRequired,
}

export function ArticleContent(props) {
	let {children} = props;
	return (
		<div className="tt-screen-content">
			<div className="container-fluid px-sm-5">
				{children}
			</div>
		</div>
	);
}
ArticleContent.propTypes = {
	children: PropTypes.node.isRequired,
};

const useArticleBodyStyles = makeStyles(theme => ({
	wrapper: {
		maxWidth: "650px",
		margin: "0 auto",
	},
}), {name: "ArticleBody"});

export function ArticleBody(props) {
	let {children, socialMedia, articleTitle} = props;
	const classes = useArticleBodyStyles();
	return (
		<ArticleContent>
			<div className={classes.wrapper}>
				{children}
			</div>
		</ArticleContent>
	);
}
ArticleBody.propTypes = {
	children: PropTypes.node,
	/** deprecated */
	socialMedia: PropTypes.bool,
	/** deprecated */
	articleTitle: PropTypes.string,
}

const useArticleCardStyles = makeStyles(theme => ({
	wrapper: {
		marginRight: "auto",
		marginLeft: "auto",
		marginBottom: theme.spacing(6),
		maxWidth: "600px",
	},
}), {name: "ArticleCard"});

export function ArticleCard(props) {
	let {id, title, to, imgData, children} = props;
	const classes = useArticleCardStyles();

	const img = !imgData ? null : (
		<div><GatsbyImage alt={title} image={imgData}/></div>
	);
	const titleContent = (<Typography gutterBottom variant="h5" component="h2">{title}</Typography>);

	return (
		<section>
			<Card id={id} elevation={8} className={classes.wrapper}>
				{to ? (
					<RouterButton component={CardActionArea} to={to}>{img}</RouterButton>
				) : (
					img
				)}
				<CardContent>
					{to ? (
						<RouterLink to={to} suppressDecoration>{titleContent}</RouterLink>
					) : (
						titleContent
					)}
					<Typography variant="body2" color="textSecondary" component="p">
						{children}
					</Typography>
				</CardContent>
			</Card>
		</section>
	);
}
ArticleCard.propTypes = {
	id: PropTypes.string,
	title: PropTypes.string.isRequired,
	/** Either an object containing a "pathname" or a string that is the pathname */
	to: PropTypes.any,
	/** The image data */
	imgData: PropTypes.object,
	children: PropTypes.node.isRequired,
};

const useArticleListStyles = makeStyles(theme => ({
	root: {
		counterReset: "articleListItemCounter",
	},
	gutters: {
		marginTop: "2em",
		marginBottom: "2em",
	}
}), {name: "ArticleList"});

export function ArticleList(props) {
	let {gutters, children} = props;
	const classes = useArticleListStyles();
	return (
		<div className={cn(classes.root, {[classes.gutters]: !!gutters})}>
			{children}
		</div>
	);
}
ArticleList.propTypes = {
	gutters: PropTypes.bool,
	/** The set of ArticleListItem */
	children: PropTypes.any.isRequired,
};

const useArticleListItemStyles = makeStyles(theme => ({
	listItemHeader: {
		counterIncrement: "articleListItemCounter",
		"&::before" : {
			content: 'counter(articleListItemCounter) ". "',
		},
	},
	listItemContent: {
		marginLeft: "2rem",
	},
}), {name: "ArticleListItem"});

/** a list item inside an ArticleList */
export function ArticleListItem(props) {
	let {title, component, children} = props;
	const classes = useArticleListItemStyles();
	return (
		<section>
			<Typography component="h2" variant="h3" gutterBottom className={classes.listItemHeader}>
				{title}
			</Typography>
			<ArticleCopy component={component} className={classes.listItemContent}>
				{children}
			</ArticleCopy>
		</section>
	);
}
ArticleListItem.propTypes = {
	title: PropTypes.string.isRequired,
	component: PropTypes.any,
	children: PropTypes.node.isRequired,
}

const useArticleCopyStyles = makeStyles(theme => ({
	root: {
		lineHeight: "1.65",
	},
	disclaimer: {
		fontSize: "0.8em",
		color: theme.palette.text.secondary,
		lineHeight: "1.2",
		marginTop: "2em",
		marginBottom: "2em",
	},
}), {name: "ArticleCopy"});

export function ArticleCopy(props) {
	let {component, disclaimer, className, children} = props;
	const classes = useArticleCopyStyles();
	return (
		<Typography variant="body2" component={component} paragraph={!disclaimer} className={cn(classes.root, {[classes.disclaimer]: disclaimer}, className)}>
			{children}
		</Typography>
	);
}
ArticleCopy.propTypes = {
	component: PropTypes.any,
	/** Appliess styling for disclaimer text */
	disclaimer: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
}

const useArticleQuoteStyles = makeStyles(theme => {
	const isDark = theme.palette.type === "dark";
	return {
		root: {
			position: "relative",
			marginTop: "2rem",
			color: theme.palette.text.primary,
		},
		icon: {
			position: "absolute",
			top: "-3px",
			left: theme.spacing(2) + 1,
			transform: "translate(0, -50%)",
			marginRight: "1rem",
		},
		quote: {
			backgroundColor: isDark ? theme.palette.grey[900] : theme.palette.grey[300],
			borderWidth: "1px",
			borderStyle: "solid",
			borderColor: isDark ? theme.palette.grey[800] : theme.palette.grey[400],
			margin: "0",
			padding: "1rem",
		},
		cite: {
			fontSize: "0.9rem",
		},
	};
}, {name: "ArticleQuote"});

export function ArticleQuote(props) {
	let {cite, children} = props;
	const classes = useArticleQuoteStyles();
	return (
		<div className={classes.root}>
			<Spacing className={classes.icon}><FontAwesomeIcon icon="quote-left" size="2x"/></Spacing>
			<div>
				<blockquote className={classes.quote}>{children}</blockquote>
				{cite && (
					<div className={classes.cite}>{cite}</div>
				)}
			</div>
		</div>
	);
}
ArticleQuote.propTypes = {
	cite: PropTypes.any,
	children: PropTypes.node.isRequired,
};

const useArticleHeaderImgStyles = makeStyles(theme => ({
	root: {
		margin: "0",
		"& figcaption": {
			fontSize: "0.9rem",
			fontStyle: "italic",
			textAlign: "right",
			padding: theme.spacing(0.5) + "px 1em",
		},
	},
	imgWrapper: {
		position: "relative",
	},
	img: {
		height: "320px",
		maxHeight: "686px",
		[theme.breakpoints.up('md')]: {
			height: "auto",
			minHeight: "400px",
		},
	},
	articleTitleContainer: {
		position: "absolute",
		backgroundColor: "rgba(0, 0, 0, 0.68)",
		width: "100%",
		left: "0",
		bottom: "0",
		padding: "1.5rem 3rem",
		[theme.breakpoints.up('md')]: {
			margin: "1rem",
			width: "auto",
			boxShadow: theme.shadows[8],
		},
	},
	articleTitle: {
		color: "#fff",
		fontWeight: "700",
	},
}), {name: "ArticleHeaderImg"});

export function ArticleHeaderImg(props) {
	let {imgData, alt, caption, articleTitle} = props;
	const classes = useArticleHeaderImgStyles();

	return (
		<figure className={classes.root}>
			<div className={classes.imgWrapper}>
				<GatsbyImage alt={alt} image={imgData} className={classes.img} loading="eager"/>
				{articleTitle && (
					<div className={classes.articleTitleContainer}>
						<Typography variant="h1" className={classes.articleTitle}>
							{articleTitle}
						</Typography>
					</div>
				)}
			</div>
			{caption && (<figcaption>{caption}</figcaption>)}
		</figure>
	);
}
ArticleHeaderImg.propTypes = {
	/** The image data */
	imgData: PropTypes.object.isRequired,
	/** Passed to the img element */
	alt: PropTypes.string.isRequired,
	caption: PropTypes.node,
	/** A title to be prominently displayed on top of the image */
	articleTitle: PropTypes.string,
};

const useArticleImgStyles = makeStyles(theme => ({
	figure: {
		"& figcaption": {
			fontSize: "0.9rem",
			fontStyle: "italic",
			padding: theme.spacing(0.5) + "px 0",
		},
	},
	articleImg: {
		boxShadow: props => !!props.elevation && props.elevation <= 25 && props.elevation > 0 ? theme.shadows[props.elevation] : theme.shadows[0],
		borderRadius: "10px",
	},
	indented: {
		marginLeft: "2rem",
	},
}), {name: "ArticleImg"});

export function ArticleImg(props) {
	let {imgData, alt, caption, indented, elevation = 2} = props;
	const classes = useArticleImgStyles({elevation});
	return (
		<Spacing mt={1} mb={3}>
			<figure className={cn(classes.figure, {[classes.indented]: indented})}>
				<GatsbyImage alt={alt} image={imgData} className={classes.articleImg}/>
				{caption && (<figcaption>{caption}</figcaption>)}
			</figure>
		</Spacing>
	);
}
ArticleImg.propTypes = {
	/** The image data */
	imgData: PropTypes.object.isRequired,
	/** Passed to the img element */
	alt: PropTypes.string.isRequired,
	caption: PropTypes.node,
	/** true to indent the image */
	indented: PropTypes.bool,
	/**
	 * Shadow depth, corresponds to `dp` in the spec.
	 * It accepts values between 0 and 24 inclusive.
	 * The default is 2.
	 */
	elevation: PropTypes.number,
};
