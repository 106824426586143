import React from 'react'
import { useSiteMetadata } from '../components/SiteData'
import AppLayout from '../components/AppLayout'
import PageInfo from '../components/PageInfo'
import {
	ArticleBody,
	ArticleCopy,
	ArticleHeaderImg, ArticleImg,
	ArticleList,
	ArticleListItem,
	ArticlePage
} from '../components/common/Article'
import RouterLink from '../components/shared/RouterLink'
import { graphql } from 'gatsby'
import UnsplashPhotoCredit from '../components/common/UnsplashPhotoCredit'
import { SchemaMarkupArticleAsSupplementalBlog } from '../components/common/SchemaMarkup'

export default function PoorTimeManagement(props) {
	let {data} = props;
	const {productName} = useSiteMetadata();

	const title = "6 Useful Time Management Activities for Boosting Employee Productivity";

	return (
		<AppLayout overlayNavWhenWide>
			<PageInfo
				title={"6 Time Management Activities for Employee Productivity - " + productName}
				description={"Managing time is important for productivity. We discuss some activities to learn about this concept."}
			/>
			<SchemaMarkupArticleAsSupplementalBlog/>

			<ArticlePage>
				<ArticleHeaderImg
					alt="Time Management Activities"
					caption={<UnsplashPhotoCredit author="Gabriel Crismariu" href="https://unsplash.com/@momentsbygabriel"/>}
					imgData={data.headerImg.childImageSharp.gatsbyImageData}
					articleTitle={title}
				/>

				<ArticleBody articleTitle={title}>

					<ArticleCopy>
						Do you want to be productive? Sure, most people would say yes. In that case,
						you've got to make sure you are making the most of your available resources.
						And time is one of our{' '}<em>most important resources</em>! Thus, we can say that
						managing our time is very important if we want to be productive.
					</ArticleCopy>

					<ArticleCopy>
						To learn more about managing time, let's talk about a few activities that we
						can do. These activities show us key concepts that can help us make the most
						of our time. You may have done some team building activities and already be
						familiar with these. Even so, each has a lesson that helps us understand
						different facets of time management.
					</ArticleCopy>

					<ArticleList gutters>

						<ArticleListItem component="div" title="Observing the difference in our perception of time">
							<p>
								The perception of time is a topic of study within psychology and
								neuroscience. The idea that our perception of time might be different
								than another person is not intuitive. In other words, we can each have a
								different notion of time that occurs between any two events. You might
								want to experiment to see how different these perceptions can be.
							</p>
							<p>
								Gather some participants. Choose an amount of time such as one or two minutes.
								Each of you closes your eyes. When each participant thinks the time has
								elapsed, they should open their eyes. By noting when each person opens
								their eyes you'll have an idea of how each person perceives time.
							</p>
						</ArticleListItem>

						<ArticleListItem component="div" title="Determining when we are most productive">
							<p>
								Each person has times when they are likely to be more productive. This
								is because each person has their own rhythms. Working during those times
								would lead to higher productivity. But how can we determine when these
								times are?
							</p>
							<p>
								Ask participants to consider a recent day in the past, or to
								observe an upcoming day and make notes. It is best if the day is on the
								weekend or a day free of too many obligations. That way they can get a
								real sense of their rhythm and not become distracted by those
								obligations. During that day the participants should make notes. They
								should log times they were feeling energized, when they felt like they
								needed a break, and so on.
							</p>
							<p>
								Looking back on this knowledge will allow
								each individual to build a schedule. This schedule will allow them to
								work during their most productive times. Tracking hours spent on
								different activities can be easier if you use a tool to help out such as
								our{' '}<RouterLink to="/timesheet-app/">timesheet app</RouterLink>.
							</p>
						</ArticleListItem>

						<ArticleImg
							alt="Being Productive with Day Planner"
							caption={<UnsplashPhotoCredit author="Eric Rothermel" href="https://unsplash.com/@erothermel"/>}
							imgData={data.plannerImg.childImageSharp.gatsbyImageData}
							indented
						/>

						<ArticleListItem component="div" title="Increasing effectiveness through preparation: finding the ace">
							<p>
								In this activity, you'll need two decks of regular playing cards. To set
								up the experiment, sort one deck by grouping each suit and then ordering
								by face value. For the other deck, shuffle it so that it is completely
								randomized. Do not tell the participants how each deck was prepared.
							</p>
							<p>
								Now, give one deck to each of two participants and tell them that the
								first one to find the ace of spades wins the race. In the overwhelming
								majority of cases, the participant with the sorted deck will win. The
								lesson is that the participant with the sorted deck had the benefit of
								prior preparation. That allowed them to be more productive in the search
								and to win the race.
							</p>
						</ArticleListItem>

						<ArticleListItem component="div" title="Spending time wisely">
							<p>
								Ask participants to imagine that they were given $86,400 to spend in one
								day. They will have to give up any leftover money. Now ask them to make
								notes of what reasons they would spend the money. Now, the developers
								reading this might see where this is going. Yes, 86,400 is the number of
								seconds we have each day. So the moral of the story is that each day
								gives us a certain amount of time to spend. But we can't bank it, so we
								must make choices so that we can use our time to further our goals
								effectively.
							</p>
						</ArticleListItem>

						<ArticleImg
							alt="Money in Jar"
							caption={<UnsplashPhotoCredit author="Josh Appel" href="https://unsplash.com/@joshappel"/>}
							imgData={data.moneyImg.childImageSharp.gatsbyImageData}
							indented
						/>

						<ArticleListItem component="div" title="Seeing the big picture">
							<p>
								Sometimes we become too focused on immediate details. It's important to
								not allow this to waste time. By looking at the big picture, we can gain
								insights to help us make effective plans and use our time wisely. This
								activity will illustrate why it's important to see the big picture.
							</p>
							<p>
								Supply each participant (or each small team of participants) with some
								piece of a larger puzzle. You can use your imagination to come up with
								the task, but sections of a small jigsaw puzzle might do. You mustn't
								let the participants see the finished picture. Then they can attempt to
								solve the puzzle as quickly as they can. At some point during this
								process, you can reveal to them the finished picture. They'll likely be
								able to solve the puzzle much faster after seeing the big picture.
							</p>
						</ArticleListItem>

						<ArticleListItem component="div" title="Choosing priorities: filling the jar">
							<p>
								With this activity, you'll need a large jar such as an empty mayo jar,
								some golf balls, some small pebbles, some sand, and some water. This
								activity might not be convenient in an office setting. But it could work
								as a thought experiment too. First, put the golf balls in the jar. Some
								may say at this point that the jar is full. Yet, you could still put in
								the pebbles and they would fill the voids between the golf balls. Follow
								up in the same manner with the sand. At this point the jar is full. But
								even now, you could put some water in with the rest of the contents.
							</p>
							<p>
								The lesson is that you could only fit so much in the jar because of the
								order in which you put in the items. If you had put in the sand first,
								the golf balls and pebbles would not have any room. It was important to
								choose the right things upfront, just as it is in life. Avoid choosing
								to waste time by not prioritizing the right things.
							</p>
						</ArticleListItem>

					</ArticleList>

					<ArticleCopy>
						Did you do some of these activities with your team? Each has a lesson for us
						that can help us understand time and how to use it wisely. And you{' '}<em>should</em>
						{' '}use it wisely! After all, time is one of the most precious resources that we
						can never replenish. If you'd like to know more, we've prepared our
						{' '}<RouterLink to="/time-management-101/">Time Management 101</RouterLink>{' '}
						resource for you.
					</ArticleCopy>

				</ArticleBody>

			</ArticlePage>
		</AppLayout>
	);
}
export const query = graphql`{
  headerImg: file(relativePath: {eq: "images/articles/time-management-activities/gabriel-crismariu-sOK9NjLArCw-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH) }
  }
  plannerImg: file(relativePath: {eq: "images/articles/time-management-activities/eric-rothermel-FoKO4DpXamQ-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(width: 400, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  moneyImg: file(relativePath: {eq: "images/articles/time-management-activities/josh-appel-NeTPASr-bmQ-unsplash.jpg"}) {
    childImageSharp { gatsbyImageData(width: 400, placeholder: BLURRED, layout: CONSTRAINED) }
  }
}
`;
