import React from 'react'
import PropTypes from 'prop-types'
import AnchorLink from '../shared/AnchorLink'

export default function UnsplashPhotoCredit({text, author, href}) {
	return (
		<span>
			{text && (<>{text} &mdash; </>)}
			{'Photo by '}
			{href ? (<AnchorLink href={href} target="_blank" rel="noopener noreferrer">{author}</AnchorLink>) : author}
			{' on '}
			<AnchorLink href="https://unsplash.com" target="_blank" rel="noopener noreferrer">Unsplash</AnchorLink>
		</span>
	);
}
UnsplashPhotoCredit.propTypes = {
	text: PropTypes.string,
	author: PropTypes.string.isRequired,
	href: PropTypes.string,
}
