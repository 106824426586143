import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AnchorButton from '../shared/AnchorButton'
import { useSiteMetadata } from '../SiteData'
import BrandLogo from './BrandLogo'
import Brand from '../shared/Brand'
import Paper from '@material-ui/core/Paper'
import Spacing from '../../reused-libraries/Spacing'

const useCallToActionStyles = makeStyles(theme => ({
	root: {
		padding: "1.5rem 2rem 1.5rem 2rem",
		borderLeftWidth: "12px",
		borderColor: theme.palette.primary.main,
	},
}), {name: "CallToAction"});

export function CallToAction(props) {
	const {ctaVariant} = props;
	let { webAppUrl } = useSiteMetadata();
	const classes = useCallToActionStyles();

	if(!ctaVariant || ctaVariant === 'try-free-plan') {
		return (
			<Paper variant="outlined" square className={classes.root}>
				<Spacing mb={1}><BrandLogo logoVariant="blackout"/></Spacing>
				<Typography gutterBottom variant="h5" component="h2">
					Time tracking, expenses, and invoicing&mdash;all in one.
				</Typography>
				<Typography variant="body2" paragraph>
					Try <Brand/> now with a free plan.
				</Typography>
				<AnchorButton variant="contained" color="primary" href={webAppUrl + "/app/signup"}>
					Get Started
				</AnchorButton>
			</Paper>
		);
	} else {
		return null;
	}
}
CallToAction.propTypes = {
	/** Determines the content of the CTA.  If not supplied, defaults to 'try-free-plan'. */
	ctaVariant: PropTypes.oneOf(['none', 'try-free-plan']),
}
